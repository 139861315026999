import "./Footer.scss";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faInstagram,
  faPinterestP,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

library.add(faYoutube, faFacebookF, faTwitter, faInstagram, faPinterestP);

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_row footer_row_top"></div>

      <div className="footer_row footer_row_middle">
        <div className="footer__container">
          <div className="footer_row_middle_content">
            <div id="footer_column_contact" className="footer_content_column">
              <p className="footer_content_text footer_content_text_big">
                Bedankt en tot&nbsp;8&nbsp;juli!
              </p>
            </div>

            <div
              id="footer_column_powered"
              className="footer_content_column"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
