import "./BasicText.scss";
import React from "react";

const BasicText = (props) => {
  const title = props.title;
  const content = props.content;
  const ctaText = props.ctaText;
  const url = props.url || "";
  const modifier = props.modifier;
  let button;

  if (ctaText) {
    button = (
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = url;
        }}
        className="basicTextBlock_button"
      >
        {ctaText}
      </button>
    );
  }
  return (
    <div className={`basicTextBlock basicTextBlock--${modifier}`}>
      <div className="basicTextBlock_flex_container">
        <h2 className="basicTextBlock_title">{title}</h2>
        <p className="basicTextBlock_content">{content}</p>
        {button}
      </div>
    </div>
  );
};

export default BasicText;
