import "./App.scss";
import Hero from "../Hero/Hero";
import Navigation from "../Navigation/Navigation";
import BasicText from "../BasicText/BasicText";
import { Fragment } from "react/cjs/react.production.min";
import Footer from "../Footer/Footer";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Hero />
      <div className="App__flexContainer">
        <BasicText
          title="RSVP"
          content={
            <Fragment>
              Geef s.v.p. vóór 19 juni hier je aanwezigheid aan. Hier kun je
              tevens je dieetwensen en wijn arrangement optie doorgeven.
            </Fragment>
          }
          modifier="first"
          ctaText="Aanwezigheid doorgeven"
          url="https://forms.gle/zBgwFT227KjHzBYN9"
        />
        <BasicText
          title="Ceremoniemeester"
          content={
            <Fragment>
              Wil je iets organiseren, voordragen of voel je je even eenzaam?
              Neem dan contact op met onze ceremoniemeester Sharda!
            </Fragment>
          }
          modifier="second"
          ctaText="Contact opnemen"
          url="mailto:sharda@audreyvictoria.com"
        />
      </div>
      <div className="App__flexContainer App__flexContainer--creme">
        <BasicText
          title="Informatie"
          content={
            <Fragment>
              <b> Dresscode:</b>
              <span>Chic</span>
              <br></br>
              <br></br>
              <b>Fifth NRE:</b>
              <span>NRE Terrein</span>
              <span>Gasfabriek 5</span>
              <span>5613 CP Eindhoven</span>
              <br></br>
              <br></br>
              <b>Voor navigatie:</b>
              <span> Nachtegaallaan 15 of Treurenburgstraat 57</span>
              <br></br>
              <br></br>
              <b> Parkeren:</b>
              <span>
                Parkeren kan betaald (tot 21:00)<br></br> op het parkeerterrein
                voor de deur.
              </span>
            </Fragment>
          }
        />
      </div>
      <Footer />
    </div>
  );
}

export default App;
