import "./Hero.scss";
import React from "react";
import mainImage from "../../assets/images/mainImage.svg";
import us from "../../assets/images/a_k.jpg";
const Hero = () => {
  return (
    <div className="hero">
      <img className="hero__logo" src={mainImage} />
      <img className="hero__image" src={us} />
    </div>
  );
};

export default Hero;
