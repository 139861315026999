import "./Navigation.scss";
import React from "react";

const Navigation = () => {
  return (
    <div className="navigation">
      <div className="navigation_inner content_width">
        <div className="logo_container"></div>
      </div>
    </div>
  );
};

export default Navigation;
